<label *ngIf="!!label" [ngClass]="{ 'is-invalid': invalid }">
    {{ label }}
    <fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon>
</label>
<div class="form-group">
    <ng-select
        placeholder="{{ label }}"
        bindLabel="name"
        [items]="selectionOptions"
        [multiple]="false"
        [clearable]="clearable"
        [hideSelected]="true"
        [closeOnSelect]="true"
        [clearSearchOnAdd]="true"
        (change)="_onChange($event)"
        (focus)="_onTouched()"
        [ngClass]="{ 'form-control is-invalid': invalid }"
        [formControl]="ngControl.control"
    >
    </ng-select>
    <app-input-errors-indicator *ngIf="invalid" [errors]="ngControl.errors" [fieldName]="label" [invalid]="invalid"></app-input-errors-indicator>
</div>
