import { Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CustomControlValueAccessor } from "../shared/custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-input-generic-field",
    templateUrl: "./input-generic-field.component.html",
    styleUrls: ["./input-generic-field.component.scss"]
})
export class InputGenericFieldComponent extends CustomControlValueAccessor<string | number> {
    @Input() type = "text";
    @Input() textFieldName: string;
    @Input() checkDuplicateNames?: string[] | string;
    @Input() isParentFormSubmitted: boolean;
    @Input() patternErrorMessage: string;
    @Input() lockTip: string;
    @Input() isRestartRequiredTip: boolean;
    @Input() circleTip: string;
    @Input() labelText?: string;
    @Input() placeHolder?: string;
    @Input() autoComplete?: string;
    @Input() disabled?: boolean;
    @Input() passwordTip?: boolean;
    @Input() underLabel?: string;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }

    onChange(newValue: string) {
        const parsedValue = this.type === "number" ? parseFloat(newValue) : newValue;
        this._onChange(parsedValue);
    }
}
