import { Component, Input, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "app-copy-button",
    template: ` <button [class]="className" (click)="copy()" type="button" [title]="title">
        <fa-icon icon="clipboard"></fa-icon>
    </button>`
})
export class CopyButtonComponent {
    private cbs = inject(ClipboardService);
    private translate = inject(TranslateService);
    @Input() stringToCopy: string;
    @Input() className = "btn btn-outline-primary";
    @Input() title = this.translate.instant("COPY");
    copy() {
        this.cbs.copy(this.stringToCopy);
    }
}
