import {
    AfterViewInit,
    OnDestroy,
    Component,
    ComponentRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    QueryList,
    SimpleChanges,
    ViewChildren,
    ViewContainerRef,
    ViewChild
} from "@angular/core";
import { ComponentType } from "src/app/components/shared/table-list/table-list.component";
import { WidgetHeaderLayout } from "./widget-section-header/widget-section-header.component";
//
import { GridStackWidget } from "gridstack";
import { GridstackComponent, NgGridStackOptions, nodesCB } from "gridstack/dist/angular";
import { TourService } from "ngx-ui-tour-md-menu";
import { Subscription } from "rxjs";
import { ResizeService } from "src/app/services/resize.service";

type ComponentInputs<Component extends ComponentType> = Partial<{
    [K in keyof InstanceType<Component>]: () => InstanceType<Component>[K];
}>;

export type WidgetWithoutLayout = {
    title: string;
    component: ComponentType;
    inputs: ComponentInputs<WidgetWithoutLayout["component"]>;
};

export type WidgetLayout = WidgetHeaderLayout & {
    widthLevel?: number;
    isFullyMaximized?: boolean;
    //
    id?: string;
    x?: number;
    y?: number;
    w?: number;
    h?: number;
};

export type Widget = WidgetWithoutLayout & WidgetLayout;

@Component({
    selector: "app-widget-section",
    templateUrl: "./widget-section.component.html",
    styleUrls: ["./widget-section.component.scss"]
})
export class WidgetSectionComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() widgets: Widget[];
    @Output() widgetsChange = new EventEmitter<GridStackWidget[]>();
    @Input() isMultiSelect: boolean;
    @Input() isLocked = false;
    @Input() isDuplicateComponent = false;
    @Input() showLinkTitle = false;
    @Output() linkClick = new EventEmitter<void>();
    @ViewChildren("container", { read: ViewContainerRef }) containers: QueryList<ViewContainerRef>;
    @ViewChild("hiddencontainer", { read: ViewContainerRef }) hiddenContainer: ViewContainerRef;
    @ViewChild(GridstackComponent) gridComponent?: GridstackComponent;

    localWidgets: Widget[] = [];
    showSizeButtons = false;
    widgetComponentsRefs: ComponentRef<ComponentType>[] = [];
    isProcessing = true;
    isMobile = false;
    isExpanded = false;
    refreshing = true;
    resizing = false;

    public gridOptions: NgGridStackOptions = {
        column: 8,
        handle: ".drag-button",
        cellHeight: "112px",
        alwaysShowResizeHandle: "mobile",
        animate: false,
        float: false,
        resizable: {
            handles: "n,s,e,w,se,sw,ne,nw"
        }
    };

    private resizeSubscription: Subscription;

    constructor(public tourService: TourService, private resizeService: ResizeService) {
        this.resizeSubscription = this.resizeService.getCurrentSize.subscribe(x => {
            this.isMobile = x < 3;
        });
    }

    ngAfterViewInit() {
        // pre-render all widgets
        for (const widget of this.widgets) {
            const component = this.hiddenContainer.createComponent(widget.component, {});
            this.updateComponentInputs(component, widget.inputs);
            this.widgetComponentsRefs.push(component);
            component.changeDetectorRef.detectChanges();
        }

        this.isProcessing = true;
        this.updateGrid();
        this.isProcessing = false;

        this.gridComponent?.grid?.on("resizestart", () => {
            this.resizing = true;
        });

        this.gridComponent?.grid?.on("resizestop", () => {
            this.resizing = false;
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.widgets && this.containers) {
            const hiddenWidgets = this.widgets.filter(widget => !widget.isSelected);
            this.resetWidgetComponents(hiddenWidgets);

            this.isProcessing = true;
            this.updateGrid();
            this.isProcessing = false;
        }

        if (changes.isLocked || changes.isMultiSelect) {
            this.updateResize(this.isMultiSelect ? !this.isLocked : false);
            this.updateMove(this.isMultiSelect && !this.isLocked);
        }
    }

    refresh() {
        // this fixes weird bug where the widgets are reversed
        if (!!this.gridComponent?.gridstackItems) {
            this.gridComponent.gridstackItems.forEach((item: any, index) => {
                const widget = this.widgets.find(w => w.id === item.options.id);
                if (widget) item.options = { ...widget };
            });
        }
        this.refreshing = false;
    }

    updateResize(isLocked: boolean) {
        // gridComponent is undefined on first ngOnChanges, setTimeout prevents console error
        setTimeout(() => {
            this.gridComponent?.grid?.enableResize(isLocked);
        });
    }

    updateMove(enableDrag: boolean) {
        setTimeout(() => {
            this.gridComponent?.grid?.enableMove(enableDrag);
        });
    }

    renderComponents() {
        // this renders the widget -components- in the DOM
        for (const [index, container] of this.containers.toArray().entries()) {
            const widget = this.localWidgets[index];
            const component = this.widgetComponentsRefs.find((ref, refIndex) => {
                return this.isDuplicateComponent ? refIndex === index : ref.componentType === widget.component;
            });
            this.updateComponentInputs(component, widget.inputs);
            if (container.element.nativeElement.previousElementSibling !== component.location.nativeElement)
                container.move(component.hostView, 0);
        }
    }

    updateGrid() {
        this.localWidgets = this.widgets.filter(widget => widget.isSelected && !widget.isHidden);

        setTimeout(() => {
            if (!this.isMultiSelect && !!this.gridComponent?.grid) {
                // stretch selected widget
                this.gridComponent.grid.update(this.gridComponent.gridstackItems.get(0).el, {
                    w: +this.gridOptions.column,
                    h: +this.gridOptions.column / 2,
                    x: 0,
                    y: 0
                });
                this.refreshing = false;
            } else {
                this.refresh();
            }
            this.renderComponents();
            this.updateResize(this.isMultiSelect ? !this.isLocked : false);
            this.updateMove(this.isMultiSelect && !this.isLocked);
        });
    }

    ngOnDestroy() {
        this.widgetComponentsRefs.forEach(component => component.destroy());
        this.resizeSubscription?.unsubscribe();
    }

    resetWidgetComponents(widgets: Widget[]) {
        for (const [index, widget] of widgets.entries()) {
            const componentRef = this.widgetComponentsRefs.find(ref => ref.componentType === widget.component);
            if (this.hiddenContainer.indexOf(componentRef.hostView) === -1)
                this.hiddenContainer.move(componentRef.hostView, this.hiddenContainer.length);
        }
    }

    updateComponentInputs(compoRef: ComponentRef<ComponentType>, inputs: Widget["inputs"]) {
        for (const [inputKey, getInputValue] of Object.entries(inputs)) {
            const configsInputValue = getInputValue();
            const componentRefInputValue = compoRef.instance[inputKey];
            const isComponentRefHasConfigsInputValue =
                typeof configsInputValue === "function"
                    ? componentRefInputValue?.toString() === configsInputValue?.toString()
                    : JSON.stringify(componentRefInputValue) === JSON.stringify(configsInputValue);
            if (!isComponentRefHasConfigsInputValue) {
                compoRef.setInput(inputKey, configsInputValue);
            }
        }
    }

    public widgetChanged(data: nodesCB) {
        if (this.isProcessing) return;
        if (!this.isMultiSelect) return;
        if (data.nodes.length === 0) return;

        setTimeout(() => {
            const widgetState = [...this.widgets];

            const gridState = <Widget[]>this.gridComponent.grid.save(false, false);
            for (const updatedWidget of gridState) {
                const widgetIndex = widgetState.findIndex(w => w.id === updatedWidget.id);
                if (widgetIndex === -1) continue;
                widgetState[widgetIndex] = { ...updatedWidget };
            }

            this.widgetsChange.emit(widgetState);
        });
    }

    // ngFor unique node id to have correct match between our items used and GS
    public identify(index: number, w: GridStackWidget) {
        if (!w.id) throw new Error("Widget must have an id");
        return w.id; // or use index if no id is set and you only modify at the end...
    }

    goToLink(data) {
        this.linkClick.emit(data.title);
    }

    toggleExpand(widget: Widget) {
        widget.isExpanded = !widget.isExpanded;
    }
}
