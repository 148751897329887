import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subscriber, BehaviorSubject, Subject } from "rxjs";
import { StatusTextPipe } from "./../pipes/status-text.pipe";
import { TranslateService } from "@ngx-translate/core";
import { Constants } from "./../constants/constants";
import {
    Tag,
    AccessKey,
    Note,
    UserPermissions,
    APIResponse,
    ZixiPlus,
    ZixiObject,
    ActiveState
} from "./../models/shared";

import * as _ from "lodash";
import { regexPasswordLevels } from "../components/shared/password-strength-icon/password-strength-icon.component";
import {
    Details,
    DetailsLayout
} from "../components/shared/new-details-page/details-section/details-section.component";
import { Widget, WidgetLayout } from "../components/shared/new-details-page/widget-section/widget-section.component";
import {
    WidgetHeader,
    WidgetHeaderLayout
} from "../components/shared/new-details-page/widget-section/widget-section-header/widget-section-header.component";
import { DecimalPipe } from "@angular/common";

@Injectable({
    providedIn: "root"
})
export class SharedService {
    private detailPanelView = new BehaviorSubject<string>("");

    tokens = [
        ["{", "}"],
        ["[", "]"]
    ];

    private splitterPosition = new BehaviorSubject<number>(null);
    get getSplitterPosition() {
        return this.splitterPosition.asObservable();
    }
    setSplitterPosition(val: number) {
        this.splitterPosition.next(val);
        if (val) localStorage.setItem("splitter-position", val.toString());
        else localStorage.removeItem("splitter-position");
    }

    splitterResized$ = new Subject<void>();

    constructor(
        private http?: HttpClient,
        private translate?: TranslateService,
        private stp?: StatusTextPipe,
        private decimalPipe?: DecimalPipe
    ) {}

    getAccessKeys(): Observable<AccessKey[]> {
        return new Observable((observe: Subscriber<AccessKey[]>) => {
            this.http
                .get<{ success: boolean; result: AccessKey[] }>(Constants.apiUrl + Constants.apiUrls.accessKeys)
                .subscribe(
                    result => {
                        observe.next(result.result);
                        observe.complete();
                    },
                    // eslint-disable-next-line no-console
                    error => console.log(this.translate.instant("COULD_NOT_LOAD_ACCESS_KEYS"), error)
                );
        });
    }

    prepStatusSortFields(arg: ZixiPlus) {
        let scoreAsc = 0;
        let scoreDesc = 0;
        let useActiveStates = false;
        const status = this.stp.transform(arg).toUpperCase();
        switch (status) {
            case "ERROR":
                scoreAsc = -300;
                scoreDesc = -100;
                useActiveStates = true;
                break;
            case "WARNING":
                scoreAsc = -400;
                scoreDesc = -200;
                useActiveStates = true;
                break;
            case "OK":
                scoreAsc = -500;
                scoreDesc = -300;
                break;
            case "PENDING":
                scoreAsc = -200;
                scoreDesc = -400;
                break;
            case "DISABLED":
                scoreAsc = -100;
                scoreDesc = -500;
                break;
            case "TERMINATED":
                scoreAsc = -90;
                scoreDesc = -510;
                break;
            case "NOT_ASSIGNED":
                scoreAsc = -80;
                scoreDesc = -520;
                break;
            case "CHANNEL_DISABLED":
                scoreAsc = -60;
                scoreDesc = -540;
                break;
            case "FLOW_DISABLED":
                scoreAsc = -50;
                scoreDesc = -550;
                break;
            default:
                //Log an error
                scoreAsc = -40;
                scoreDesc = -560;
                // eslint-disable-next-line no-console
                // console.log("Error: Invalid status in prepStatusSortFields", status, arg);
                break;
        }

        // active_mute & acknowledged
        const mute = arg.active_mute ? 0 : 1;
        const ack = arg.acknowledged ? 0 : 2;
        const total = mute + ack;

        scoreAsc += total;
        scoreDesc += total;

        if (useActiveStates) {
            if (arg.activeStates) {
                let active = arg.activeStates.length;
                if (active > 100) {
                    //Log an error
                    active = 100;
                }
                scoreAsc += active / 100;
                scoreDesc += active / 100;
            }
        }

        arg._sortData = {
            sortableStatusAsc: scoreAsc,
            sortableStatusDesc: scoreDesc
        };
    }
    prepAllStatusSortItems(items) {
        items.forEach(item => {
            this.prepStatusSortFields(item);
        });
    }

    // Sort
    sort<T>(items: T[], column: string | ((object: T) => string), direction: string): T[] {
        if (items.length < 2 || direction === "") {
            // eslint-disable-next-line no-console
            // console.log("Error!: ", items, direction, column);
            return items;
        }
        let _column = column;
        if (column === "_sortData.sortableStatus") {
            this.prepAllStatusSortItems(items);
            _column = "_sortData.sortableStatusDesc";
            if (direction === "asc") {
                _column = "_sortData.sortableStatusAsc";
            }
        }

        if (column === "_sortData.targetStatus") {
            _column = "target._sortData.sortableStatusDesc";
            if (direction === "asc") {
                _column = "target._sortData.sortableStatusAsc";
            }
        }

        return items.sort((a, b) => {
            let avalue: unknown = a;
            let bvalue: unknown = b;

            if (typeof _column === "string") {
                _column.split(".").forEach(x => {
                    if (avalue) avalue = avalue[x];
                    if (bvalue) bvalue = bvalue[x];
                });
            } else {
                avalue = _column(a);
                bvalue = _column(b);
            }
            if (typeof avalue === "string" || typeof bvalue === "string") {
                if (avalue == null) avalue = "";
                if (bvalue == null) bvalue = "";
                const res = this.compare(`${avalue}`.toLowerCase(), `${bvalue}`.toLowerCase());
                return direction === "asc" ? res : -res;
            } else {
                const res = this.compare(avalue, bvalue);
                return direction === "asc" ? res : -res;
            }
        });
    }

    getResourceTagsByType(type?: string, ro?: boolean): Observable<Tag[]> {
        if (type) {
            return new Observable((observe: Subscriber<Tag[]>) => {
                this.http
                    .get<{ success: boolean; result: Tag[] }>(
                        Constants.apiUrl + Constants.apiUrls.resourceTags + "/" + type + (ro ? "?ro=1" : "")
                    )
                    .subscribe(
                        result => {
                            observe.next(result.result);
                            observe.complete();
                        },
                        // eslint-disable-next-line no-console
                        error => console.log(this.translate.instant("COULD_NOT_LOAD_RESOURCE_TAGS"), error)
                    );
            });
        } else {
            return new Observable((observe: Subscriber<Tag[]>) => {
                this.http
                    .get<{ success: boolean; result: Tag[] }>(
                        Constants.apiUrl + Constants.apiUrls.resourceTags + (ro ? "?ro=1" : "")
                    )
                    .subscribe(
                        result => {
                            observe.next(result.result);
                            observe.complete();
                        },
                        // eslint-disable-next-line no-console
                        error => console.log(this.translate.instant("COULD_NOT_LOAD_RESOURCE_TAGS"), error)
                    );
            });
        }
    }

    // New
    async getNotes(type: string, id: number) {
        try {
            const result = await this.http
                .get<{ success: boolean; result: Note[] }>(
                    Constants.apiUrl + Constants.apiUrls[type] + "/" + id + "/notes"
                )
                .toPromise();
            return result.result;
        } catch (error) {
            return false;
        }
    }

    async getNoteByID(type: string, id: number, noteID: number) {
        const result = await this.http
            .get<{ success: boolean; result: Note }>(
                Constants.apiUrl + Constants.apiUrls[type] + "/" + id + "/notes/" + `${noteID}`
            )
            .toPromise();
        return result.result;
    }

    async deleteNote(type: string, id: number, note: Note) {
        try {
            await this.http
                .delete<APIResponse<number>>(
                    Constants.apiUrl + Constants.apiUrls[type] + "/" + id + "/notes/" + `${note.id}`
                )
                .toPromise();
            return true;
        } catch (error) {
            return false;
        }
    }

    async addNote(type: string, id: number, model: Record<string, unknown>) {
        try {
            await this.http
                .post<{ success: boolean }>(Constants.apiUrl + Constants.apiUrls[type] + "/" + id + "/notes", model)
                .toPromise();
            return true;
        } catch (error) {
            return false;
        }
    }

    async updateNote(type: string, id: number, noteID: number, model: Record<string, unknown>) {
        try {
            await this.http
                .put<{ success: boolean }>(
                    Constants.apiUrl + Constants.apiUrls[type] + "/" + id + "/notes/" + `${noteID}`,
                    model
                )
                .toPromise();
            return true;
        } catch (error) {
            return false;
        }
    }

    // Detail Panel View
    get getDetailPanelView() {
        return this.detailPanelView.asObservable();
    }

    setDetailPanelView(val: string) {
        this.detailPanelView.next(val);
    }

    // Calc # of table rows
    calcRows(listPanelHeight: number, listTopHeight: number, listBottomHeight: number) {
        const rowHeight = 31.2;
        const listHeight = listPanelHeight - listTopHeight - listBottomHeight - 64 - 16 - 16;
        const listRows = Math.max(Math.floor(listHeight / rowHeight), 10);
        return listRows;
    }

    calcRowsTab(listPanelHeight: number, listTopHeight: number, listBottomHeight: number) {
        const rowHeight = 31.2;
        const listHeight = listPanelHeight - listTopHeight - listBottomHeight - 64 - 16;
        const listRows = Math.max(Math.floor(listHeight / rowHeight), 10);
        return listRows;
    }

    // List panel width
    listPanelWidth(innerWidth: number, pointerRelativeXpos: number, minWidth?: number, maxWidth?: number) {
        // Minimum width set
        if (!minWidth) minWidth = 380;
        // Maximum width set
        if (!maxWidth) maxWidth = innerWidth / 2.5; // 2.85
        return Math.max(minWidth, Math.min(maxWidth, pointerRelativeXpos)) + "px";
    }

    // Edit Zixi Object
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    canEditZixiObject(object: any, tags: Tag[], permissions: UserPermissions) {
        if (!object || object.readOnly || !permissions) return false;
        if (
            !tags &&
            !(
                permissions.is_admin ||
                permissions.is_objects_manager ||
                permissions.is_zixi_support_write ||
                permissions.is_zixi_admin
            )
        )
            return false;
        return (
            (_.intersection(_.map(tags, "id"), _.map(object.resourceTags, "id")).length !== 0 &&
                !permissions.is_zixi_support) ||
            permissions.is_admin ||
            permissions.is_objects_manager ||
            permissions.is_zixi_support_write ||
            permissions.is_zixi_admin
        );
    }

    // Random password generator
    generateStrongPassword() {
        const passwordChars: string[] = [];
        passwordChars.push(..._.sampleSize("abcdefghijklmnopqrstuvwxyz", 6));
        passwordChars.push(_.sample("ABCDEFGHIJKLMNOPQRSTUVWXYZ"));
        passwordChars.push(_.sample("1234567890"));
        passwordChars.push(_.sample("!@#$%^&*"));
        const password = _.shuffle(passwordChars).join("");

        if (!regexPasswordLevels.good.test(password)) {
            // eslint-disable-next-line no-console
            console.log("Problem in generateStrongPassword function");
        }

        return password;
    }

    // Keep Order
    keepOrder(a: unknown /*, b: unknown*/): unknown {
        return a;
    }

    // Compare
    private compare(v1: unknown, v2: unknown) {
        if (v1 === undefined && v2 === undefined) return 0;
        if (v1 === undefined) return -1;
        if (v2 === undefined) return 1;
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0;
    }

    private zixiObjectTransforms = {
        resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" },
        alerting_profile_id: { objectsKey: "alertingProfile", valuePath: "id" }
    };

    getZixiObjDiff<T>(
        newObj: Record<string, unknown>,
        prevObj: T,
        ignores?: string[],
        objectTransforms?: { [key: string]: { objectsKey: string; valuePath: string } }
    ): Record<string, unknown> {
        const completeObjectTransforms = Object.assign({}, objectTransforms, this.zixiObjectTransforms);

        return _.omitBy(newObj, (val, key) => {
            let existingVal = prevObj[key];

            // skip keys we don't submit
            if (ignores && ignores.includes(key)) return true;

            // map from objects to ids if needed
            if (completeObjectTransforms[key] && completeObjectTransforms[key].objectsKey) {
                existingVal = prevObj[completeObjectTransforms[key].objectsKey];
                if (Array.isArray(existingVal)) {
                    if (existingVal.length) {
                        existingVal = existingVal.map(
                            (o: Record<string, unknown>) => o[completeObjectTransforms[key].valuePath]
                        );
                    } else {
                        existingVal = completeObjectTransforms[key].valuePath;
                    }
                } else if (existingVal) {
                    existingVal =
                        prevObj[completeObjectTransforms[key].objectsKey][completeObjectTransforms[key].valuePath];
                }
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            function checkValues(v1: any, v2: any) {
                if (v2 && Array.isArray(v1)) {
                    return v1.length === v2.length && _.every(v1, (v, i) => checkValues(v, v2[i]));
                } else if (v2 && typeof v1 === "object" && v1 != null) {
                    return _.every(v1, (v, i) => checkValues(v, v2[i])) && _.every(v2, (v, i) => checkValues(v, v1[i]));
                }
                return v1 === v2;
            }

            return checkValues(val, existingVal);
        });
    }

    // Check if daylight savings time
    isDST(d: Date) {
        const jan = new Date(d.getFullYear(), 0, 1).getTimezoneOffset();
        const jul = new Date(d.getFullYear(), 6, 1).getTimezoneOffset();
        return Math.max(jan, jul) !== d.getTimezoneOffset();
    }

    // Check if object empty
    isEmptyObject(obj: unknown) {
        return obj && Object.keys(obj).length === 0;
    }

    // create array of strings from boolean search
    createArrayFromBooleanSearch(inputStr: string): string[] {
        const arr: string[] = [];
        const split = inputStr.trim().split(/([{,},[,\]]| AND | AND| OR| OR |NOT | NOT )/);
        split.forEach(s => {
            if (s !== "" && s !== " " && s.trim() !== "AND" && s.trim() !== "OR" && s.trim() !== "NOT")
                arr.push(s.trim());
        });
        return arr;
    }

    // check if expression is balanced
    areParenthesesBalanced = (inputStr: string) => {
        if (inputStr === null) {
            return true;
        }

        const expression = inputStr.split("");
        const stack = [];

        for (let i = 0; i < expression.length; i++) {
            if (this.isParenthesis(expression[i])) {
                if (this.isOpenParenthesis(expression[i])) {
                    stack.push(expression[i]);
                } else {
                    if (stack.length === 0) {
                        return false;
                    }
                    const top = stack.pop(); // pop off the top element from stack
                    if (!this.matchesParenthesis(top, expression[i])) {
                        return false;
                    }
                }
            }
        }
        const returnValue = stack.length === 0 ? true : false;
        return returnValue;
    };

    isParenthesis(char) {
        const str = "{}[]";
        if (str.indexOf(char) > -1) {
            return true;
        } else {
            return false;
        }
    }

    isOpenParenthesis(parenthesisChar) {
        for (let j = 0; j < this.tokens.length; j++) {
            if (this.tokens[j][0] === parenthesisChar) {
                return true;
            }
        }
        return false;
    }

    matchesParenthesis(topOfStack, closedParenthesis) {
        for (let k = 0; k < this.tokens.length; k++) {
            if (this.tokens[k][0] === topOfStack && this.tokens[k][1] === closedParenthesis) {
                return true;
            }
        }
        return false;
    }

    operatorsCheck = (inputStr: string) => {
        const split = inputStr.trim().split(" ");
        for (let k = 0; k < split.length; k++) {
            if (split[k] === "") return true;
            // check if no operator exists between parentheses
            if (split[k].includes("(") && split[k].includes(")")) return true;
            // check if no operator exists between booleans
            if (split[k].includes("(") || split[k].includes(")")) {
                const f = "false";
                const t = "true";
                const falses = split[k].match(new RegExp("\\b" + f + "\\b", "g"));
                const trues = split[k].match(new RegExp("\\b" + t + "\\b", "g"));
                const total = (falses ? falses.length : 0) + (trues ? trues.length : 0);
                if (total > 1) return true;
            }
        }
        return false;
    };

    // matches function for table filter
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    matches = (obj: any, term: string, func): boolean => {
        // Split at brackets, AND, OR
        const split = term.trim().split(/([{}[\]]| AND | AND| OR| OR |NOT | NOT )/);
        // boolean search login
        let e = "";
        split.forEach(s => {
            if (s.trim() === "AND") e += " && ";
            else if (s.trim() === "OR") e += " || ";
            else if (s.trim() === "NOT") e += "!";
            else if (s === "[" || s === "{") e += "(";
            else if (s === "]" || s === "}") e += ")";
            else if (s !== "" && s !== " ") {
                if (func(obj, s.trim())) e += "true";
                else e += "false";
            } else {
                // do nothing
            }
        });

        const first = e.trim().split(" ")[0];
        const last = e.trim().split(" ").pop();
        // return true if empty
        if (e === "") return true;
        // return false if parentheses aren't balanced
        if (!this.areParenthesesBalanced(term)) return false;
        // return false if starts with AND or OR
        if (first === "||" || first === "&&") return false;
        // return false if ends with AND or OR
        if (last === "||" || last === "&&" || last === "!") {
            if (e.trim().split(" ").length > 1) {
                const index = e.trim().lastIndexOf(" ");
                e = e.trim().substring(0, index);
            } else return false;
        }
        // ignore
        // if (last === "true)true" || last === "false)false" || last === "true)false" || last === "false)true") return false;
        // return false if two operators in a row
        if (this.operatorsCheck(e)) return false;
        return this.parseBoolStr(e);
    };

    parseBoolStr(str) {
        const expressions = {};
        const expressionRegex = new RegExp("\\((?:(?:!*true)|(?:!*false)|(?:&&)|(?:\\|\\|)|\\s|(?:!*\\w+))+\\)");
        let expressionIndex = 0;
        str = str.trim();
        while (str.match(expressionRegex)) {
            let match = str.match(expressionRegex)[0];
            const expression = "boolExpr" + expressionIndex;
            str = str.replace(match, expression);
            match = match.replace("(", "").replace(")", "");
            expressions[expression] = match;
            expressionIndex++;
        }
        return this.evalBoolStr(str, expressions);
    }

    evalBoolStr(str, expressions) {
        const conditions = str.split(" ");
        if (conditions.length > 0) {
            let validity = this.toBoolean(conditions[0], expressions);
            for (let i = 1; i + 1 < conditions.length; i += 2) {
                const comparer = conditions[i];
                const value = this.toBoolean(conditions[i + 1], expressions);
                switch (comparer) {
                    case "&&":
                        validity = validity && value;
                        break;
                    case "||":
                        validity = validity || value;
                        break;
                }
            }
            return validity;
        }
        return false;
    }

    toBoolean(str, expressions) {
        let inversed = 0;
        while (str.indexOf("!") === 0) {
            str = str.replace("!", "");
            inversed++;
        }
        let validity;
        if (str.indexOf("boolExpr") === 0) {
            validity = this.evalBoolStr(expressions[str], expressions);
        } else if (str === "true" || str === "false") {
            validity = str === "true";
        } else {
            // check for str, if doesn't exist, query is not valid
            try {
                if (str) validity = Window[str]();
            } catch (e) {
                return false;
            }
        }
        for (let i = 0; i < inversed; i++) {
            validity = !validity;
        }
        return validity;
    }

    isTarget(object: ZixiObject) {
        return (
            object.type === "http" ||
            object.type === "s3" ||
            object.type === "mediastore" ||
            object.type === "gcp" ||
            object.type === "azure" ||
            object.type === "rtmp" ||
            object.type === "mediaconnect" ||
            object.type === "push" ||
            object.type === "pull" ||
            object.type === "udp_rtp" ||
            object.type === "rist" ||
            object.type === "srt" ||
            object.type === "ndi" ||
            object.type === "cdi" ||
            object.type === "jpegxs" ||
            object.type === "medialive_http" ||
            object.type === "publishing_target" ||
            object.type === "zixi_pull" ||
            object.type === "zixi_push" ||
            object.type === "rtmp_push" ||
            object.type === "srt_targets" ||
            object.type === "ndi_targets"
        );
    }

    getKBPSContent(kbps?: number) {
        if (!kbps && kbps !== 0) return "-";
        // Remove comma?
        // const kbpsTransformed = this.decimalPipe.transform(kbps, "1.0-0").replace(/,/g, "");
        // Don't remove comma
        const kbpsTransformed = this.decimalPipe.transform(kbps, "1.0-0");
        return `${kbpsTransformed}`;
    }

    getLastError = (object: ZixiObject): ActiveState | undefined => {
        if (object.objectState && object.objectState.state === "success") return undefined;
        const errorState = (object.activeStates || []).find(as => as.type === "error");
        const warningState = (object.activeStates || []).find(as => as.type === "warning");
        return errorState || warningState;
    };

    getNetwork(location) {
        return [location?.ip?.isp, location?.ip?.region_name, location?.ip?.country_name].filter(e => !!e).join(", ");
    }

    // New pages
    getWithLayout(data: Details[], layouts?: DetailsLayout[]): Details[];
    getWithLayout(data: Widget[], layouts?: WidgetLayout[]): Widget[];
    getWithLayout(data: WidgetHeader[], layouts?: WidgetHeaderLayout[]): WidgetHeader[];
    getWithLayout(
        data: (Details | Widget | WidgetHeader)[],
        layouts?: (DetailsLayout | WidgetLayout | WidgetHeaderLayout)[]
    ): (Details | Widget | WidgetHeader)[] {
        if (!layouts) return data;
        return data
            .map(entity => {
                const matchLayout = layouts.find(layout => layout.title === entity.title) || {};
                return { ...entity, ...matchLayout };
            })
            .sort((entity1, entity2) => entity1.index - entity2.index);
    }

    getPercentContent(value?: number) {
        return value || value === 0 ? this.decimalPipe.transform(value, "1.0-1") : "-";
    }

    getStatusClass(
        value?: number,
        minValue = 50,
        maxValue = 75
    ): "status-good" | "status-warning" | "status-error" | "" {
        if (value === null) return "";
        if (value === undefined) return "";
        if (value < minValue) return "status-good";
        if (value >= maxValue) return "status-error";
        return "status-warning";
    }
}
