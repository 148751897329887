import { Component, Input } from "@angular/core";
import { NgControl } from "@angular/forms";
import { CustomControlValueAccessor } from "../shared/custom-control-value-accessor/custom-control-value-accessor";

@Component({
    selector: "app-radio-input",
    templateUrl: "./radio-input.component.html"
})
export class RadioInputComponent extends CustomControlValueAccessor<string> {
    @Input() label: string;
    @Input() disabled: boolean;
    @Input() lockTip: boolean;
    @Input() circleTip: boolean;
    @Input() isRestartRequiredTip: boolean;
    @Input() isParentFormSubmitted: boolean;
    @Input() inputKeyAndValues: { [key: string]: string };
    @Input() tooltip?: string;

    constructor(public ngControl: NgControl) {
        super(ngControl);
    }
}
