import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { ZecTypes } from "../../../zec-list/zec-list.component";
import { ClipboardService } from "ngx-clipboard";
import { Router } from "@angular/router";
import { Constants } from "src/app/constants/constants";

@Component({
    selector: "app-zec-details",
    templateUrl: "./zec-details.component.html"
})
export class ZecDetailsComponent implements OnChanges {
    @Input() zec: ZecTypes;
    @Input() canEdit: boolean;
    pwVisible = false;
    zecType: string;

    urls = Constants.urls;

    constructor(private cbs: ClipboardService, private router: Router) {
        this.zecType = this.router.url.replace("s/", "/").split("/")[2].toUpperCase();
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.zec) {
            if (changes.zec.previousValue && changes.zec.currentValue) {
                if (changes.zec.previousValue.id !== changes.zec.currentValue.id) {
                    this.pwVisible = false;
                }
            }
        }
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }
}
