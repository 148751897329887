import { Component, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClipboardService } from "ngx-clipboard";
import { Subscription, filter } from "rxjs";
import { NavigationStart, Router } from "@angular/router";
import { ZecTypes } from "../../../zec-list/zec-list.component";

@Component({
    selector: "app-zec-config-dialog",
    templateUrl: "./zec-config-dialog.component.html"
})
export class ZecConfigDialogComponent implements OnDestroy {
    zec: ZecTypes;
    tunnelersHost: string;

    private routeSubscription: Subscription;

    constructor(public activeModal: NgbActiveModal, private router: Router, private cbs: ClipboardService) {
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                // Close modal on navigation event
                this.activeModal.close();
            });
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
    }

    confirm() {
        this.activeModal.close();
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }
}
