import { Component, OnInit, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Source, FailoverSource, Broadcaster } from "../../../../models/shared";
import { DeviceDetectorService } from "ngx-device-detector";
import { take } from "rxjs/operators";

import { Constants } from "../../../../constants/constants";
import { BroadcastersService } from "src/app/components/broadcasters/broadcasters.service";
import { ClipboardService } from "ngx-clipboard";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { TagsService } from "src/app/pages/configuration/tags/tags.service";
import { SourcesService, SOURCE_CONTENT_ANALYSIS } from "../../sources.service";
import { Observable } from "rxjs";

@Component({
    selector: "app-source-details",
    templateUrl: "./source-details.component.html"
})
export class SourceDetailsComponent implements OnInit, OnChanges {
    @Input() source: Source;
    @Input() canEdit: boolean;
    CONTENT_ANALYSIS = SOURCE_CONTENT_ANALYSIS;
    pwVisible = false;
    canVLC: boolean;
    local_closed_caption = true;
    isPushOrPull = false;

    urls = Constants.urls;
    constants = Constants;

    primaryFailoverSources: FailoverSource[] = [];
    secondaryFailoverSources: FailoverSource[] = [];
    slateFailoverSources: FailoverSource[] = [];

    multiviewSources = [];
    isAdmin: boolean;

    targetBroadcaster: Observable<Broadcaster>;

    constructor(
        private deviceService: DeviceDetectorService,
        private broadcastersService: BroadcastersService,
        private cbs: ClipboardService,
        private userService: UsersService,
        private tagsService: TagsService,
        private sourcesService: SourcesService
    ) {
        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
    }

    get contentAnalysis() {
        return this.sourcesService.getSourceContentAnalysisState(this.source);
    }

    async ngOnInit() {
        this.initFailoverSources();
        this.initMultiviewSources();

        if (
            this.source.target_broadcaster_id &&
            this.source.broadcaster_cluster_id &&
            this.source.target_broadcaster_id !== -1 &&
            this.source.target_broadcaster_id !== -2 &&
            this.source.target_broadcaster_id !== -3 &&
            this.source.target_broadcaster_id !== -4
        ) {
            this.targetBroadcaster = this.broadcastersService.getTargetBroadcaster(this.source.target_broadcaster_id);
        }

        this.userService
            .getCurrentUser()
            .pipe(take(1))
            .subscribe(user => {
                this.isAdmin = !!user.is_admin || !!user.is_objects_manager;
            });
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.source) {
            this.isPushOrPull = this.sourcesService.isPushOrPull(this.source);

            if (this.source) {
                this.initFailoverSources();
                this.initMultiviewSources();
            }

            if (changes.source.previousValue && changes.source.currentValue) {
                if (
                    changes.source.previousValue.id !== changes.source.currentValue.id ||
                    !changes.source.previousValue.hasFullDetails
                ) {
                    this.pwVisible = false;
                    if (
                        this.source.target_broadcaster_id &&
                        this.source.broadcaster_cluster_id &&
                        this.source.target_broadcaster_id !== -1 &&
                        this.source.target_broadcaster_id !== -2 &&
                        this.source.target_broadcaster_id !== -3 &&
                        this.source.target_broadcaster_id !== -4
                    ) {
                        this.targetBroadcaster = this.broadcastersService.getTargetBroadcaster(
                            this.source.target_broadcaster_id
                        );
                    }
                }
            }
        }
    }

    initFailoverSources() {
        const failoverSources = this.source.failoverSources ?? [];
        this.primaryFailoverSources = failoverSources.filter(fs => fs.priority > 1);
        this.secondaryFailoverSources = failoverSources.filter(fs => fs.priority === 1);
        this.slateFailoverSources = failoverSources.filter(fs => fs.priority === 0);
    }

    initMultiviewSources() {
        const multiviewSources = this.source.multiviewSources ?? [];
        this.multiviewSources = multiviewSources.sort((a, b) => a.grid_y - b.grid_y);
    }

    copyPassword(pw: string) {
        this.cbs.copy(pw);
    }

    isVip() {
        return this.tagsService.isObjectVip(this.source.resourceTags);
    }
}
