import { TitleCasePipe } from "@angular/common";
import { Injectable, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";

type EntityOrName =
    | {
          name?: string;
      }
    | string;

@Injectable({
    providedIn: "root"
})
export class TitleService {
    private translate = inject(TranslateService);
    private titleCasePipe = inject(TitleCasePipe);
    private titleService = inject(Title);

    setTitle(entityType: string, action = "NEW", entityOrName?: EntityOrName) {
        const translatedString = this.translate.instant(entityType);
        const entityName = !entityOrName ? "" : typeof entityOrName === "string" ? entityOrName : entityOrName.name;
        const actionTitle = this.titleCasePipe.transform(action);
        let title = "";
        if (!action && !entityName) title = translatedString;
        else if (!action) title = `${translatedString} - ${entityName}`;
        else if (!entityName) title = `${translatedString} - ${actionTitle}`;
        else title = `${translatedString} - ${actionTitle} ${entityName}`;
        this.titleService.setTitle(title);
    }
}
