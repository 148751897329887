<div class="details-form">
    <div class="form-group">
        <h5 translate>TYPE</h5>
        <div>
            <span translate>{{ zecType | translate }}</span>
        </div>
    </div>
    <div class="form-group">
        <h5 translate>API_CREDENTIALS</h5>
        <div>
            <a href="javascript:void(0)" (click)="pwVisible = true" *ngIf="!pwVisible">{{ "SHOW" | translate }}</a>
            <span *ngIf="!pwVisible">&nbsp;/&nbsp;</span>
            <a href="javascript:void(0)" (click)="copyPassword(zec.api_password)" *ngIf="!pwVisible">{{ "COPY_PASSWORD" | translate }}</a>
            <span *ngIf="pwVisible"
                >{{ zec.api_user }}
                <a href="javascript:void(0)" title="{{ 'COPY_USER' | translate }}" (click)="copyPassword(zec.api_user)"
                    ><fa-icon icon="clipboard" size="sm"></fa-icon
                ></a>
                / {{ zec.api_password }}
                <a href="javascript:void(0)" title="{{ 'COPY_PASSWORD' | translate }}" (click)="copyPassword(zec.api_password)"
                    ><fa-icon icon="clipboard" size="sm"></fa-icon></a
                ><app-password-strength-icon class="d-inline-block ms-1" [password]="zec.api_password"></app-password-strength-icon></span
            ><a class="ms-1" href="javascript:void(0)" (click)="pwVisible = false" *ngIf="pwVisible">{{ "HIDE" | translate }}</a>
        </div>
    </div>
    <div class="form-group" [ngClass]="{ 'd-none': zec.state === 'pending' || !canEdit }">
        <h5 translate>OPEN</h5>
        <div>
            <a href="{{ zec.configure_link }}" target="_blank">
                <fa-icon icon="external-link-alt" size="sm"></fa-icon>
                {{ zecType | translate }}
            </a>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.source_ip">
        <h5 translate>SOURCE_IP</h5>
        <div>
            <span>{{ zec.status.source_ip }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.hostid">
        <h5 translate>HOST_ID</h5>
        <div>
            <span>{{ zec.status.hostid }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.about && zec.status.about.platform">
        <h5 translate>PLATFORM</h5>
        <div>
            <span>{{ zec.status.about.platform }}</span>
        </div>
    </div>
    <app-details-location [location]="zec.location"></app-details-location>
    <div class="form-group" *ngIf="zec.status && zec.status.about && zec.status.about.version">
        <h5 translate>VERSION</h5>
        <div>
            <span>{{ zec.status.about.version }}</span>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.accessKey">
        <h5 translate>SSH_KEY</h5>
        <div>
            <span *ngIf="zec.accessKey.readOnly || (!zec.accessKey.readOnly && !zec.accessKey.hasRsaPrivate)">{{ zec.accessKey.name }}</span>
            <a *ngIf="!zec.accessKey.readOnly && zec.accessKey.hasRsaPrivate" href="/api/access_keys/{{ zec.accessKey.id }}/download">
                <fa-icon icon="download" size="sm"></fa-icon>
                {{ zec.accessKey.name }}
            </a>
        </div>
    </div>
    <div class="form-group" *ngIf="zec.status && zec.status.activation_key_link">
        <h5 translate>ACTIVATION_KEY</h5>
        <div>
            <span
                ><a [href]="zec.status?.activation_key_link" target="_blank"><fa-icon icon="external-link-alt" size="sm"></fa-icon> License Details</a></span
            >
        </div>
    </div>
    <div class="form-group" *ngIf="zec.alertingProfile">
        <h5 translate>ALERTING_PROFILE</h5>
        <div>
            <a [routerLink]="['/' + urls.configuration.eventsManagement, zec.alertingProfile.id]">
                {{ zec.alertingProfile.name }}
            </a>
        </div>
    </div>
    <div class="form-group">
        <h5 translate>TERMINATION_PROTECTION</h5>
        <div>
            <span>{{ zec.termination_protection ? ("ENABLED" | translate) : ("DISABLED" | translate) }}</span>
        </div>
    </div>
</div>
