<content-loading-animation *ngIf="loading"></content-loading-animation>
<form [formGroup]="form" *ngIf="!loading" [mustMatch]="matchControlNames">
    <app-form-title
        [titles]="[titleMsg]"
        [isEdit]="isEdit"
        [isClone]="isClone"
        [hasTourStartButton]="hasTourStartButton"
        [tourStartTooltipString]="tourStartTooltipString"
        [openTitleToolTip]="openTitleToolTip"
    ></app-form-title>

    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <ng-content></ng-content>
                </div>
            </div>
        </div>
    </div>
</form>
