<div *ngIf="invalid" class="text-invalidation">
    <div zmid="validation-error-required" *ngIf="errors?.required && fieldName === 'Tags'">
        {{ "AT_LEAST_ONE_TAG" | translate }} {{ "IS_REQUIRED" | translate }}.
    </div>
    <div zmid="validation-error-required" *ngIf="errors?.required && fieldName !== 'Tags'">{{ fieldName }} {{ "IS_REQUIRED" | translate }}.</div>
    <div *ngIf="errors?.minlength">{{ fieldName }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}.</div>
    <div *ngIf="errors?.duplicateName">{{ fieldName }} {{ "MUST_BE_UNIQUE" | translate }}.</div>
    <div *ngIf="errors?.pattern">
        {{ fieldName }}
        <ng-container *ngIf="errors.pattern.requiredPattern === constants.validators.no_blanc_start_or_end">
            {{ "CAN_NOT_END_OR_START_BLANC_SPACE" | translate }}.
        </ng-container>
        <ng-container *ngIf="errors.pattern.requiredPattern !== constants.validators.no_blanc_start_or_end">
            {{ patternErrorMessage || "CAN_NOT_CONTAIN_SPECIAL_CHARACTERS" | translate }}.
        </ng-container>
    </div>
    <div *ngIf="errors?.min">{{ fieldName }} {{ "MUST_BE_GREATER_THAN_OR_EQUAL_TO" | translate }} {{ min }}.</div>
    <div *ngIf="errors?.max">{{ fieldName }} {{ "MUST_BE_LESS_THAN_OR_EQUAL_TO" | translate }} {{ max }}.</div>
    <div *ngIf="errors?.mustMatch">{{ "PASSWORDS_MUST_MATCH" | translate }}.</div>
</div>
