<!-- Grid Stack -->
<div class="gridstack-container" [ngClass]="{ isTab: !isMultiSelect, widgetResizing: resizing }">
    <gridstack [options]="gridOptions" (changeCB)="widgetChanged($event)" [ngClass]="{ 'opacity-0': refreshing }">
        <gridstack-item *ngFor="let widget of localWidgets; let i = index; trackBy: identify" [options]="widget">
            <div class="grid-item-container" id="{{ widget.title }}" [tourAnchor]="i === 0 ? 'tenthCustomLayoutAnchor' : ''">
                <div class="grid-item" [ngClass]="{ expanded: widget.isExpanded }">
                    <h3 *ngIf="isMultiSelect || isMobile" [tourAnchor]="i === 0 ? 'eleventhCustomLayoutAnchor' : ''">
                        <span *ngIf="!showLinkTitle">{{ widget.title }}</span>
                        <a
                            *ngIf="showLinkTitle"
                            href="javascript:void(0)"
                            [ngStyle]="{ 'z-index': 2, position: 'relative' }"
                            title="{{ widget?.name }}"
                            (click)="goToLink(widget)"
                        >
                            {{ widget.title }}
                        </a>
                        <div class="drag-button" *ngIf="isMultiSelect && !isLocked && !widget.isExpanded">
                            <fa-icon [ngStyle]="{ top: '1px', position: 'relative' }" icon="grip-lines-vertical" size="sm"></fa-icon>
                        </div>
                        <!-- Expand -->
                        <div class="expand-button">
                            <button
                                type="button"
                                class="btn btn-sm border-0 btn-outline-secondary"
                                (click)="toggleExpand(widget)"
                                title="{{ 'EXPAND' | translate }}"
                                *ngIf="!widget.isExpanded"
                            >
                                <fa-icon icon="expand" size="sm"></fa-icon>
                                <span class="sr-only">{{ "EXPAND" | translate }}</span>
                            </button>
                            <!-- Contract -->
                            <button
                                type="button"
                                class="btn btn-sm border-0 btn-outline-secondary"
                                (click)="toggleExpand(widget)"
                                title="{{ 'CONTRACT' | translate }}"
                                *ngIf="widget.isExpanded"
                            >
                                <fa-icon icon="compress" size="sm"></fa-icon>
                                <span class="sr-only">{{ "CONTRACT" | translate }}</span>
                            </button>
                        </div>
                    </h3>
                    <div class="widget-container">
                        <ng-container #container></ng-container>
                    </div>
                </div>
            </div>
        </gridstack-item>
    </gridstack>
</div>
<div style="display: none">
    <ng-container #hiddencontainer></ng-container>
</div>
