import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from "@angular/core";
import { Subscription } from "rxjs";
import * as _ from "lodash";
import moment from "moment";

import { EventsService } from "../../../pages/events/events.service";
import { UsersService } from "../../../pages/account-management/users/users.service";
import { ZmEvent, EventsFilter } from "../../../pages/events/event";
import { UserPermissions, ZixiObject } from "./../../../models/shared";
import { SCREEN_SIZE } from "src/app/models/screen-size.enum";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "../modals/modal.service";
import { TableSchema } from "../table-list/table-list.component";
import { DatePipe, UpperCasePipe } from "@angular/common";
import { ZxNgbHighlightComponent } from "../zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "../../../components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxStatusFullComponent } from "../zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "../zx-status-full/zx-status-full.table-adapter";
import { DesnakePipe } from "src/app/pipes/desnake.pipe";
import { StatusClassPipe } from "src/app/pipes/status-class.pipe";
import { ZxEventsTableRowActionsComponent } from "../zx-events-table-row-actions/zx-events-table-row-actions.component";
import { assignEventsTableRowActionsInputsFactory } from "../zx-events-table-row-actions/zx-events-table-row-actions.table-adapter";
import { ZxDateTimeDisplayComponent } from "../zx-date-time-display/zx-date-time-display.component";
import { assignDateTimeDisplayInputsFactory } from "../zx-date-time-display/zx-date-time-display.table-adapter";
import { MatDialog } from "@angular/material/dialog";
import {
    EditRuleDialogComponent,
    EditRuleDialogData
} from "src/app/pages/configuration/events-management/edit-rule-dialog/edit-rule-dialog.component";

type EventsObjects = ZixiObject | ZixiObject[] | number[];
interface EventsTypedObjects {
    [type: string]: EventsObjects;
}

@Component({
    selector: "zx-events",
    templateUrl: "./zx-events.component.html",
    providers: [DatePipe, UpperCasePipe, StatusClassPipe, DesnakePipe]
})
export class ZxEventsComponent implements OnInit, OnChanges, OnDestroy {
    @Input() objects: EventsTypedObjects;
    @Input() bordered?: boolean;
    @Input() quickReport = true;
    @Input() autoRows? = true;
    @Input() id?: number;

    loading = true;
    events: ZmEvent[] = [];
    userPermissions: UserPermissions;

    eventsFilter: EventsFilter;

    filter: Record<string, boolean> = {
        error: true,
        warning: true,
        info: true,
        success: true
    };

    prevEventsLength = 0;

    morePages = true;

    tableExpanded: boolean;
    size: SCREEN_SIZE;

    private eventsSubscription: Subscription;
    private userPermissionsSubscription: Subscription;

    private pageSize = 100;

    get tableColumnsSchema(): TableSchema[] {
        return this._tableColumnsSchema;
    }
    set tableColumnsSchema(newValue: TableSchema[]) {
        this._tableColumnsSchema = newValue;
    }

    _tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("OBJECT"),
            columnDef: "object",
            width: 220,
            visible: true,
            sticky: 1,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory({
                statusCallback: row => this.statusClassPipe.transform((row as unknown as ZmEvent)?.event_type),
                textCallback: row => this.getColumnText(row, "object"),
                navigateOnClick: true
            })
        },
        {
            header: this.translate.instant("DATE/TIME"),
            columnDef: "date/time",
            width: 190,
            visible: true,
            sticky: 2,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<ZmEvent>(
                row => row.event_date,
                "MMM d, y, h:mm:ss a"
            )
        },
        {
            header: this.translate.instant("MESSAGE"),
            columnDef: "message",
            width: 200,
            visible: true,
            sticky: 3
        },
        {
            header: this.translate.instant("RULE"),
            columnDef: "rule",
            width: 180,
            visible: false,
            sticky: 4,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory(
                row => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "-"),
                row => (this.isRuleExists(row) ? this.getColumnText(row, "rule") : "-"),
                row => this.isRuleExists(row)
            )
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            sticky: 5,
            width: 50,
            align: "right",
            visible: true,
            stickyToLast: true,
            component: ZxEventsTableRowActionsComponent,
            assignComponentsInputs: assignEventsTableRowActionsInputsFactory(
                row => this.canCreateIncident(row),
                row => this.canManageEvent(row),
                row => this.createIncident(row as unknown as ZmEvent),
                row => this.manageEvent(row as unknown as ZmEvent)
            )
        }
    ];

    constructor(
        private es: EventsService,
        private userService: UsersService,
        private modalService: ModalService,
        private translate: TranslateService,
        private uppercasePipe: UpperCasePipe,
        private statusClassPipe: StatusClassPipe,
        private desnakePipe: DesnakePipe,
        private dialog: MatDialog
    ) {}

    // Reset Filter
    resetFilter() {
        this.events = [];
        this.prevEventsLength = 0;
    }

    async refresh() {
        let firstEventDate;
        if (this.events && this.events.length > 0) {
            firstEventDate = moment(_.first(this.events).created_at);
        } else {
            firstEventDate = moment().subtract(1, "month");
        }

        const filter = Object.assign({
            fromDate: firstEventDate.add(1, "second"),
            toDate: moment(),
            objects: this.getTypedIds(),
            msgTypes: this.filter,
            offset: null
        });

        await this.es.loadNewEvents(filter);
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (
            changes.objects &&
            (!_.isEqual(
                this.getTypedIds(changes.objects.currentValue),
                this.getTypedIds(changes.objects.previousValue)
            ) ||
                (changes.objects.currentValue !== changes.objects.previousValue && !changes.objects.previousValue))
        ) {
            this.loading = true;
            if (!changes.objects.currentValue) return;

            // Check localStorage
            const ls = localStorage.getItem("zxEvents.filter");
            if (ls) {
                const filter = JSON.parse(ls);
                this.filter = { ...filter };
            }

            this.resetFilter();
            this.es.getEvents(this.prepFilter());
        }
    }

    private getTypedIds(objects?: EventsTypedObjects) {
        return _.reduce(
            objects || this.objects,
            (typedIds, object: EventsObjects, type: string) => {
                if (object instanceof Array) {
                    typedIds[type] = _.map(object, (o: ZixiObject | number) => {
                        if (typeof o === "number") return o;
                        else return o.id;
                    });
                } else if (object && (object.id || object.id === 0)) {
                    typedIds[type] = [object.id];
                }

                return typedIds;
            },
            {}
        );
    }

    // Prep Filter
    prepFilter(overrides: Partial<EventsFilter> = {}): EventsFilter {
        this.eventsFilter = Object.assign(
            {
                offset: _.isEmpty(this.events) ? undefined : _.last(this.events).id,
                objects: this.getTypedIds(),
                msgTypes: this.filter,
                pageSize: this.pageSize
            },
            overrides
        );
        return this.eventsFilter;
    }

    ngOnInit() {
        this.loading = true;

        this.userPermissionsSubscription = this.userService.userPermissions.subscribe(obj => {
            this.userPermissions = obj;
        });

        this.eventsSubscription = this.es.events.subscribe(events => {
            this.events = events;
            if (this.events) {
                this.loading = false;
            }
        });

        this.loading = true;
    }

    ngOnDestroy() {
        this.userPermissionsSubscription.unsubscribe();
        this.eventsSubscription.unsubscribe();
        this.es.clearEvents();
    }

    // Manage Event
    async manageEvent(event: ZmEvent) {
        this.dialog.open<EditRuleDialogComponent, EditRuleDialogData>(EditRuleDialogComponent, {
            data: event,
            width: "600px"
        });
    }

    async createIncident(event: ZmEvent) {
        const object = {
            id: event.object_id,
            name: event.object_name,
            type: event.object_type
        };
        const finalObject = { [event.object_type]: object };

        await this.modalService.addIncident(
            finalObject,
            moment(event.event_date).subtract(15, "minutes"),
            moment(event.event_date).add(15, "minutes"),
            event
        );
    }

    // Reload Events
    async reloadEvents() {
        this.loading = true;
        this.resetFilter();
        await this.es.refreshEvents(this.prepFilter());
        this.loading = false;
    }

    quickReportParameters() {
        const reportEventsFilter = this.prepFilter({
            pageSize: 1000,
            fromDate: undefined,
            toDate: undefined,
            offset: undefined,
            msgTypes: undefined
        });
        return this.es.getEventsParameters(reportEventsFilter).toString();
    }

    isRuleExists(row: unknown | ZmEvent): boolean {
        const event: ZmEvent = row as unknown as ZmEvent;
        return (
            event.error_group && event.error_code && (event.event_type === "error" || event.event_type === "warning")
        );
    }

    getColumnText(row: unknown | ZmEvent, columnType: string): string {
        const event: ZmEvent = row as unknown as ZmEvent;

        switch (columnType) {
            case "rule":
                return event?.error_group &&
                    event?.error_code &&
                    (event.event_type === "error" || event.event_type === "warning")
                    ? `${this.translate.instant(
                          this.uppercasePipe.transform(event.error_group)
                      )} - ${this.translate.instant(this.uppercasePipe.transform(event.error_code))}`
                    : "-";
            case "object": {
                const commonText: string = event.object_name ? " - " + event.object_name : "";

                switch (event.object_type) {
                    case "groups":
                        return this.translate.instant("USER_GROUP");
                    case "srt_targets":
                        return `${this.translate.instant("SRT")} ${this.translate.instant("TARGET")}${commonText}`;
                    case "ndi_targets":
                        return `${this.translate.instant("NDI")} ${this.translate.instant("TARGET")}${commonText}`;
                    default:
                        return `${this.desnakePipe.transform(event.object_type)} ${commonText}`;
                }
            }
        }
    }

    canCreateIncident(row: unknown | ZmEvent): boolean {
        const event: ZmEvent = row as unknown as ZmEvent;
        return (
            (event.event_type === "error" || event.event_type === "warning") &&
            event.object_type !== "remote_access" &&
            event.object_type !== "task_sets"
        );
    }

    canManageEvent(row: unknown | ZmEvent): boolean {
        const event: ZmEvent = row as unknown as ZmEvent;
        return (
            event.error_code &&
            event.error_group &&
            (event.event_type === "error" ||
                (event.event_type === "warning" &&
                    (this.userPermissions.is_zixi_support_write ||
                        this.userPermissions.is_zixi_admin ||
                        this.userPermissions.is_admin ||
                        this.userPermissions.is_objects_manager)))
        );
    }

    onFilterChange(filterChanged: Record<string, boolean>): void {
        this.filter = { ...filterChanged };
        localStorage.setItem("zxEvents.filter", JSON.stringify(this.filter));
        this.reloadEvents();
    }

    async currentPageInfo(page: { pageSize: number; pageIndex: number }) {
        this.pageSize = page.pageSize;
        if (this.pageSize * (page.pageIndex + 2) >= this.events.length) {
            const prevCount = this.events.length;
            const pageSize = (this.events.length % this.pageSize) + this.pageSize;
            await this.es.loadMoreEvents(
                this.prepFilter({
                    pageSize
                })
            );

            if (this.events.length < prevCount + pageSize) this.morePages = false;
        }
    }
}
